import React, { useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import ClaimsApi from '../ClaimsApi';
import Stage from '../../../elements/Stage/Stage';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';
import { Alert, DataTableCell, FieldCheckbox, InputTable, InputTableCell, InputTableRow, ModelLink } from '@dex/bubl-dash';
import { loop, titleCase } from '@dex/bubl-helpers';

const ClaimStageMerge: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const app = useApp();

    const process = ClaimsApi.update(data.id, "stage_merge");

    const claims = ClaimsApi.get({});

    useEffect(() => {

        claims.cancel();

        if (form.values.merge !== "yes") return;

        claims.run({
            params: {
                where: {
                    agency_id: data.agent.agency_id,
                    status: { inq: ["approved", "pending"] }
                },
                include: [
                    {
                        relation: 'agent',
                        preset: "link"
                    }
                ]
            }
        })

    }, [form.values.merge]);

    let columns: any = [
        {
            label: "",
            style: { width: 50 }
        },
        {
            label: "Claim ID",
        },
        {
            label: "Advisor",
        },
        {
            label: "Status",
        },
        {
            label: "Total Amount",
        }
    ];

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"merge"}
            heading={"Merge Claims"}
            editable={false}
            showForm={!data.merge}
            showMeta={data.merge}
            showSave={form.changed}
            passed={data.merge === "yes"}
            columnA={[
                {
                    key: "merge",
                    label: "Merge Claims",
                    format: "titleCase"
                },
            ]}
            columnB={[
                {
                    label: "Merged Claims",
                    key: "merged_ids",
                    list: true
                },
            ]}
            columnC={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRadio
                                form={form}
                                name={"merge"}
                                label={"Merge Claims"}
                                defaultValue={form.defaults.merge}
                                required={true}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                            {form.values.merge === "yes" &&
                                <Alert
                                    type="warning"
                                    message={"Merge Claims"}
                                    description={"Claims will be merged into " + data.id + " and this action cannot be reversed"}
                                />
                            }


                        </Col>

                        {form.values.merge === "yes" && (

                            <>

                                <Col col={{ xs: 24, md: 24 }}>

                                    <InputTable
                                        columns={columns}
                                    >

                                        {claims.data?.data?.length > 0 && claims.data?.data.map((claim, index) => (

                                            <InputTableRow key={claim.id}>

                                                <InputTableCell>
                                                    <FieldCheckbox
                                                        form={form}
                                                        name={"merged_ids[" + index + "]"}
                                                        options={[{ label: "", value: claim.id }]}
                                                    />
                                                </InputTableCell>

                                                <DataTableCell>
                                                    <ModelLink data={{ id: claim.id }} modelName="Claims" />
                                                </DataTableCell>

                                                <DataTableCell>
                                                    <ModelLink data={{ fullName: claim.agent.fullName, id: claim.agent_id, modelName: "Users" }} />
                                                </DataTableCell>

                                                <DataTableCell>
                                                    {titleCase(claim.status)}
                                                </DataTableCell>

                                                <DataTableCell>
                                                    RM {claim.total}
                                                </DataTableCell>

                                            </InputTableRow>

                                        ))}

                                    </InputTable>




                                </Col>

                            </>

                        )}


                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, claims.hash]);

}

export default ClaimStageMerge;
