import TerminalsReportsIndex from './TerminalsReportsIndex';
import TerminalsReportsCreate from './TerminalsReportsCreate';
import TerminalsReportsSettings from './TerminalsReportsSettings';
import TerminalsReportsSingle from './TerminalsReportsSingle';

export default function (app) {

    const settings = TerminalsReportsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     priority: 14,
    //     items: [
    //         {
    //             label: "Reports",
    //             path: settings.path,
    //             caps: settings.indexCaps
    //         },
    //     ],
    // });

    TerminalsReportsIndex(app, settings);

    TerminalsReportsCreate(app, settings);

    TerminalsReportsSingle(app, settings);

}