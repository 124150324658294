import TerminalsReportsSettings from "../TerminalsReports/TerminalsReportsSettings";
import TerminalsCreate from "./TerminalsCreate";
import TerminalsIndex from "./TerminalsIndex";
import TerminalsSettings from "./TerminalsSettings";
import TerminalsSingle from "./TerminalsSingle";


export default function (app) {

    const settings = TerminalsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 13,
        items: [
            {
                label: "All Terminals",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "In Stock",
                path: settings.path + "/status/in_stock",
                caps: settings.indexCaps,
            },
            {
                label: "Processing",
                path: settings.path + "/status/in_process",
                caps: settings.indexCaps,
            },
            {
                label: "Deployed",
                path: settings.path + "/status/deployed",
                caps: settings.indexCaps,
            },
            {
                label: "Delivered",
                path: settings.path + "/status/delivered",
                caps: settings.indexCaps,
            },
            {
                label: "Write-Off",
                path: settings.path + "/status/write_off",
                caps: settings.indexCaps,
            },
            {
                label: "Summary",
                path: settings.path + "/summary",
                caps: settings.indexCaps,
            },
            // {
            //     label: "Reports",
            //     path: TerminalsReportsSettings.path,
            //     caps: TerminalsReportsSettings.indexCaps,
            // },
        ],
    });

    TerminalsIndex(app, settings);

    TerminalsCreate(app, settings);

    TerminalsSingle(app, settings);

}