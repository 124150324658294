import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldAddress from '@dex/bubl-dash/src/lib/components/Fields/FieldAddress';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import { FieldAutocomplete, FieldCheckbox, FieldSelect, useApp } from '@dex/bubl-dash';
import { merchantApplications } from '../../../functions/sharedMerchant';
import { get, hashCode } from '@dex/bubl-helpers';

const MerchantsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();
    const [nav] = app.store('nav');

    const [settings] = app.store("appSettings");

    const applications = useMemo(() => {

        const { applications, defaults } = merchantApplications(settings);

        if (mode === "create" || mode === "reuse") {

            const isClone = get(nav, "currentRoute.state.params.cloneId");

            if (!isClone) {

                form.handleChange({ name: "standard_applications", value: defaults.standard, reset: true, silent: true });
                form.handleChange({ name: "optional_applications", value: defaults.optional, reset: true, silent: true });
                form.handleChange({ name: "qr_applications", value: defaults.qr, reset: true, silent: true });

            }

        }

        return applications;

    }, [settings]);

    const canManageTags = app.userCan(["manageTagsMerchants"]);

    let planQuery = { business_model: { inq: ['esa', 'esa-rent-to-own', 'esa-rental'] } };

    if (form.values.process === "bir") planQuery = { business_model: { inq: ['rent-to-own', 'rental'] } };
    else if (form.values.process === "mrm") planQuery = { business_model: { inq: ['maintenance'] } };

    if (form.values.acquirer_id) planQuery['acquirer_id'] = form.values.acquirer_id;

    const planLabels = planQuery.business_model.inq.map((item: string) => app.intl(`engagement_plans.business_model.${item}`));

    const showFullForm = (mode === "create" || mode === "reuse" || (mode === "edit" && ["new_merchant", "installation"].includes(data.status)));

    return useMemo(() => (

        <Panel
            heading={"Merchants Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                {showFullForm &&
                    <>

                        <Row gutter={8} edge={true}>

                            {mode === "create" &&
                                <>
                                    <Col col={{ xs: 24 }}>

                                        <FieldRadio
                                            button
                                            form={form}
                                            label={"Select Process"}
                                            name='process'
                                            required={true}
                                            defaultValue={form.defaults.process}
                                            options={[
                                                { value: "mrp", label: "ESA : Merchant Recruitment Process" },
                                                { value: "bir", label: "Rent To Own : Bank Installation Request" },
                                                { value: "mrm", label: "Maintenance : Mass Rollout Maintenance" },
                                            ]}
                                        />

                                    </Col>
                                    <Col col={{ xs: 24 }}>
                                        <Line />
                                    </Col>
                                </>
                            }

                            <Col col={{ xs: 24, md: 16 }}>

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Company Name"}
                                            name='company_name'
                                            required={true}
                                            defaultValue={form.defaults.company_name}
                                            transform={'uppercase'}
                                            maxLength={30}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Trading Name"}
                                            name='trading_name'
                                            required={false}
                                            defaultValue={form.defaults.trading_name}
                                            transform={'uppercase'}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Contact Person"}
                                            name='person'
                                            required={true}
                                            defaultValue={form.defaults.person}
                                            transform={'uppercase'}
                                            maxLength={30}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"E-Mail"}
                                            name='email'
                                            required={true}
                                            defaultValue={form.defaults.email}
                                            transform={'uppercase'}
                                            maxLength={50}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Phone"}
                                            name='phone'
                                            required={true}
                                            defaultValue={form.defaults.phone}
                                            maxLength={30}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldAutocomplete
                                            form={form}
                                            label={"Notification Email"}
                                            name='notification_email'
                                            required={false}
                                            defaultValue={form.defaults.notification_email}
                                            transform={'uppercase'}
                                            group='notification_email'
                                            preload={true}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24 }}>

                                        <FieldAutocomplete
                                            form={form}
                                            label={"Acquirer - Person In Charge"}
                                            name='acquirer_person'
                                            required={false}
                                            defaultValue={form.defaults.acquirer_person}
                                            transform={'uppercase'}
                                            group='acquirer_person'
                                            preload={true}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Business Registration Number (BRN)"}
                                            name='brn'
                                            required={false}
                                            defaultValue={form.defaults.brn}
                                            maxLength={12}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Tax Identification Number (TIN)"}
                                            name='tin'
                                            required={false}
                                            defaultValue={form.defaults.tin}
                                            maxLength={12}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 24 }}>

                                        <FieldText
                                            form={form}
                                            label={"SST No."}
                                            name='sst'
                                            required={false}
                                            defaultValue={form.defaults.sst}
                                            maxLength={15}
                                        />

                                    </Col>

                                    {form.values.process === "mrp" && (

                                        <>
                                            <Col col={{ xs: 24 }}>

                                                <FieldRelationship
                                                    form={form}
                                                    label={"Advisor"}
                                                    name='agent_id'
                                                    required={false}
                                                    endpoint={'Users'}
                                                    where={{ roles: 'advisor' }}
                                                    preload={true}
                                                    defaultValue={form.defaults.agent_id}
                                                />

                                            </Col>

                                            <Col col={{ xs: 24 }}>

                                                <FieldRadio
                                                    form={form}
                                                    label={"Merchant Location"}
                                                    name='merchant_location'
                                                    required={false}
                                                    defaultValue={form.defaults.merchant_location}
                                                    options={[
                                                        { value: 'headquarters', label: 'Headquarters' },
                                                        { value: 'branch', label: 'Branch' },
                                                        { value: 'additional', label: 'Additional' },
                                                    ]}
                                                />

                                            </Col>

                                        </>

                                    )}


                                </Row>

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Documents"}
                                    name={`documents`}
                                    required={false}
                                    defaultValue={form.defaults.documents}
                                    limit={20}
                                />

                            </Col>

                        </Row>

                        <Seperator
                            heading={"Acquirer Details"}
                            top={"auto"}
                            bottom={"auto"}
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 16 }}>

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24 }}>

                                        <FieldRelationship
                                            form={form}
                                            label={"Acquirer"}
                                            name='acquirer_id'
                                            required={true}
                                            endpoint={'Acquirers'}
                                            preload={true}
                                            defaultValue={form.defaults.acquirer_id}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24 }}>

                                        <FieldRelationship
                                            key={hashCode(planQuery)}
                                            form={form}
                                            label={"Engagement Plan (" + planLabels.join(", ") + ")"}
                                            name='engagement_plan_id'
                                            required={true}
                                            endpoint={'EngagementPlans'}
                                            preload={true}
                                            defaultValue={form.defaults.engagement_plan_id}
                                            where={planQuery}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldSelect
                                            form={form}
                                            label={"Action Type"}
                                            name='action_type'
                                            required={true}
                                            defaultValue={form.defaults.action_type}
                                            options={[
                                                { value: 'New Setup', label: 'New Setup' },
                                                { value: 'Swapping', label: 'Swapping' },
                                                { value: 'In-House Conversion', label: 'In-House Conversion' },
                                                { value: 'Temporary', label: 'Temporary' },
                                                { value: 'Change Management', label: 'Change Management' },
                                                { value: 'Upgrade (No Commission)', label: 'Upgrade (No Commission)' },
                                                { value: 'Swapping (No Commission)', label: 'Swapping (No Commission)' },
                                            ]}
                                        />

                                    </Col>

                                    {form.values.action_type === "In-House Conversion" &&
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Batch No"}
                                                name='batch_no'
                                                defaultValue={form.defaults.batch_no}
                                            />

                                        </Col>
                                    }

                                </Row>

                                <Line />

                            </Col>

                        </Row>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Standard Applications"}
                                    name='standard_applications'
                                    required={false}
                                    defaultValue={form.defaults.standard_applications}
                                    options={applications.standard}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Optional Applications"}
                                    name='optional_applications'
                                    required={false}
                                    defaultValue={form.defaults.optional_applications}
                                    options={applications.optional}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"QR Applications"}
                                    name='qr_applications'
                                    required={false}
                                    defaultValue={form.defaults.qr_applications}
                                    options={applications.qr}
                                />

                            </Col>

                        </Row>

                        <Seperator
                            heading={"Billing Details"}
                            top={"auto"}
                            bottom={"auto"}
                        />

                        <Row gutter={8} edge={true}>


                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldAddress
                                    form={form}
                                    label={"Billing Address"}
                                    name='billing_address'
                                    required={true}
                                    defaultValue={form.defaults.billing_address}
                                    allowedCountries={["Malaysia"]}
                                    transform={'uppercase'}
                                    maxLength={{
                                        line1: 120,
                                        line2: 120,
                                        city: 13,
                                        zipcode: 5,
                                    }}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldSwitch
                                    form={form}
                                    label={"Installation Address same as Billing Address?"}
                                    name='installation_same'
                                    required={false}
                                    defaultValue={form.defaults.installation_same}
                                />

                            </Col>

                            {!form.values.installation_same &&

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldAddress
                                        form={form}
                                        label={"Installation Address"}
                                        name='installation_address'
                                        required={false}
                                        defaultValue={form.defaults.installation_address}
                                        allowedCountries={["Malaysia"]}
                                        transform={'uppercase'}
                                        maxLength={{
                                            line1: 120,
                                            line2: 120,
                                            city: 13,
                                            zipcode: 5,
                                        }}
                                    />

                                </Col>

                            }

                        </Row>

                    </>
                }

                <Seperator
                    heading={"Additional Details"}
                    top={showFullForm ? "auto" : null}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldAutocomplete
                                    form={form}
                                    label={"Additional Tags"}
                                    name='tags'
                                    required={false}
                                    defaultValue={form.defaults.tags}
                                    group='merchant.tags'
                                    isMulti={true}
                                    preload={true}
                                    disableCreate={canManageTags ? false : true}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Additional Notes"}
                                    name='notes'
                                    required={false}
                                    defaultValue={form.defaults.notes}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Industry"}
                                    name='industry'
                                    required={false}
                                    defaultValue={form.defaults.industry}
                                    options={[
                                        { value: 'Eating places & restaurants', label: 'Eating places & restaurants' },
                                        { value: 'Grocery stores / Supermarkets', label: 'Grocery stores / Supermarkets' },
                                        { value: 'Drug stores / Pharmacies', label: 'Drug stores / Pharmacies' },
                                        { value: 'Fast food restaurants', label: 'Fast food restaurants' },
                                        { value: 'Misc. Food Stalls', label: 'Misc. Food Stalls' },
                                        { value: 'Beauty / Barber shops', label: 'Beauty / Barber shops' },
                                        { value: 'Bars / Taverns / Lounges / Discos', label: 'Bars / Taverns / Lounges / Discos' },
                                        { value: "Men's / Women's Clothing stores", label: "Men's / Women's Clothing stores" },
                                        { value: 'Cosmetic stores', label: 'Cosmetic stores' },
                                        { value: 'Family Clothing stores', label: 'Family Clothing stores' },
                                        { value: 'Furniture & Equipment stores', label: 'Furniture & Equipment stores' },
                                        { value: "Women's Ready to Wear stores", label: "Women's Ready to Wear stores" },
                                        { value: 'Health & Beauty Spas', label: 'Health & Beauty Spas' },
                                        { value: 'Telecommunication & Telephones Sales', label: 'Telecommunication & Telephones Sales' },
                                        { value: 'Electronic stores', label: 'Electronic stores' },
                                        { value: 'Others', label: 'Others' },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Branch Code"}
                                    name='branch_code'
                                    required={false}
                                    defaultValue={form.defaults.branch_code}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form >

        </Panel >


    ), [props]);

}

export default MerchantsCreateEditForm;
