import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldCheckbox, FieldDate, FieldHidden, FieldRelationship, userHasCap } from '@dex/bubl-dash';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import ServiceStageAttemptFailed from './ServiceStageAttemptFailed';
import { validateDatePassed } from '../../../functions/sharedValidation';
import ServiceStageIncompleteMessages from './ServiceStageIncompleteMessages';



const ServiceStageCollectionDetails: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_collection_details");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    const isStaff = userHasCap(["staff"]);
    const isTechnician = userHasCap(["technician"]);

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, isTechnician);

    }, []);

    const checklist = [
        { label: "Terminal - " + data.faulty_serial, value: "Terminal - " + data.faulty_serial },
    ];

    if (data.faulty_sim_card_serial) checklist.push({ label: "SIM Card - " + data.faulty_sim_card_serial, value: "SIM Card - " + data.faulty_sim_card_serial });
    if (data.faulty_swivel_serial) checklist.push({ label: "Swivel Serial No. - " + data.faulty_swivel_serial, value: "SIM Card - " + data.faulty_swivel_serial });
    if (data.faulty_router_serial) checklist.push({ label: "Router Serial No. - " + data.faulty_router_serial, value: "SIM Card - " + data.faulty_router_serial });
    if (data.faulty_docking_serial) checklist.push({ label: "Docking Serial No. - " + data.faulty_docking_serial, value: "SIM Card - " + data.faulty_docking_serial });

    checklist.push({ label: "Power Adapter", value: "Power Adapter" });

    const items = checklist.map(item => item.value);

    checklist.push({ label: "Service Report", value: "Service Report" });

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"collected_details"}
            heading={"Service Details (Retrieval)"}
            editCaps={["stage_collection_detailsServices"]}
            editable={false}
            showForm={data.collection_complete !== "yes"}
            showMeta={data.collection_complete}
            passed={data.collection_complete === "yes"}
            showSave={form.changed}
            reverse={data.collection_complete === "yes" && isStaff ? null : reverse}
            last_stage={"accepted_service_request"}
            renderMeta={() => (
                <>
                    <ServiceStageIncompleteMessages
                        data={data}
                    />
                </>
            )}
            columnA={[
                {
                    label: "Technician",
                    key: "technician",
                    format: "modelLink"
                },
                {
                    label: "Service Date",
                    key: "service_date",
                    format: "date"
                },
                {
                    label: "Service Report Photo",
                    key: "service_photo",
                    format: "file"
                },
            ]}
            columnB={[
                {
                    label: "Checklist",
                    key: "collection_checklist",
                    render: (value, row) => (
                        <>
                            {value && value.map((v, index) => (
                                <p key={index}>{v}</p>
                            ))}
                        </>
                    )
                },
            ]}
            columnC={[
                {
                    label: "Missing Items",
                    key: "collection_missing",
                    render: (value, row) => (
                        <>
                            {value && value.map((v, index) => (
                                <p key={index}>{v}</p>
                            ))}
                        </>
                    )
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                label={"Collection Complete"}
                                name='collection_complete'
                                required={true}
                                defaultValue={form.defaults.collection_complete}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    // { label: "No", value: "no" },
                                    { label: "Failed", value: "failed" }
                                ]}
                            />

                        </Col>

                        {form.values.collection_complete === "yes" &&

                            <>
                                <Col col={{ xs: 24, md: 8 }}>
                                </Col>

                                {!isTechnician &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRelationship
                                            form={form}
                                            label="Technician"
                                            name="technician_id"
                                            required={true}
                                            model={"Users"}
                                            preload={true}
                                            where={{ roles: "technician", provider_id: data.provider_id }}
                                            defaultValue={form.defaults.technician_id}
                                        />

                                    </Col>
                                }
                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldDate
                                        form={form}
                                        name={"service_date"}
                                        required={true}
                                        label={"Service Date"}
                                        defaultValue={form.defaults.service_date || ""}
                                        disabledDate={isDateDisabled}
                                    />

                                </Col>
                            </>
                        }

                    </Row>

                    {form.values.collection_complete === "failed" &&
                        <>
                            <ServiceStageAttemptFailed
                                form={form}
                                data={data}
                            />
                        </>
                    }

                    {form.values.collection_complete === "yes" && (

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Checklist"}
                                    name='collection_checklist'
                                    required={true}
                                    defaultValue={form.defaults.collection_checklist}
                                    list
                                    options={checklist}
                                />

                                <FieldHidden
                                    form={form}
                                    name='collection_items'
                                    defaultValue={items}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldUpload
                                    form={form}
                                    label={"Service Report Photo"}
                                    name="service_photo"
                                    required={true}
                                    defaultValue={form.defaults.service_photo}
                                    folder={"uploads"}
                                    limit={3}
                                />

                            </Col>

                        </Row>

                    )}

                </>

            }

        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageCollectionDetails;
