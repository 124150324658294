import React from 'react';
import InstallationStageTerminalSelected from './InstallationStageTerminalSelected';
import InstallationStageTerminalTesting from './InstallationStageTerminalTesting';
import InstallationStageTerminalPersonalization from './InstallationStageTerminalPersonalization';
import InstallationStageQualityControl from './InstallationStageQualityControl';
import InstallationStageAssignTechnician from './InstallationStageAssignTechnician';
import InstallationStageOutbound from './InstallationStageOutbound';
import InstallationStageInstallationAndTraining from './InstallationStageInstallationAndTraining';
import InstallationStageDocumentsReturned from './InstallationStageDocumentsReturned';
import InstallationStageMileageClaim from './InstallationStageMileageClaim';
import InstallationStagePrintDocuments from './InstallationStagePrintDocuments';
import { userHasCap } from '../../../functions/useUser';
import InstallationStageReviewFailed from './InstallationStageReviewFailed';
import InstallationStageCheckComplete from './InstallationStageCheckComplete';
import InstallationStageInTransit from './InstallationStageInTransit';

const InstallationStages: React.FC<any> = (props: any) => {

    const { data } = props;

    const isTechnician = userHasCap(["technician"]);

    return (

        <>

            {data.installation_training_on && (
                <>

                    <InstallationStageMileageClaim
                        data={data}
                    />

                </>

            )}

            <InstallationStageTerminalSelected
                data={data}
            />

            {!isTechnician && (

                <React.Fragment>

                    {/* if previous stage is complete */}
                    {data.terminal_selected === "yes" && (

                        <>

                            <InstallationStageTerminalTesting
                                data={data}
                            />

                        </>

                    )}


                    {/* if previous stage is complete */}
                    {data.terminal_testing === "yes" && (

                        <>

                            <InstallationStagePrintDocuments
                                data={data}
                            />

                        </>

                    )}

                    {/* if previous stage is complete */}
                    {data.terminal_testing === "yes" && (

                        <>
                            <InstallationStageTerminalPersonalization
                                data={data}
                            />
                        </>

                    )}

                    {/* if previous stage is complete */}
                    {data.terminal_personalization === "yes" && (

                        <>

                            <InstallationStageQualityControl
                                data={data}
                            />

                        </>

                    )}

                    {/* if previous stage is complete */}
                    {data.quality_control === "yes" && (

                        <>
                            <InstallationStageAssignTechnician
                                data={data}
                            />

                        </>

                    )}

                </React.Fragment>

            )}

            {/* if previous stage is complete */}
            {data.assign_technician === "yes" && (

                <>

                    <InstallationStageOutbound
                        data={data}
                    />

                </>

            )}

            {data.outbound === "yes" && data.collection_method === "postage" && (

                <>
                    <InstallationStageInTransit
                        data={data}
                    />

                </>

            )}

            {(
                (data.status !== "failed" && data.status !== "cancelled") &&
                (
                    (data.in_transit === "yes" || data.package_status === "delivered") ||
                    (data.collection_method !== "postage" && data.outbound === "yes")
                )
            ) && (
                    <InstallationStageInstallationAndTraining
                        data={data}
                    />
                )}

            {(data.status === "failed") && (
                <InstallationStageReviewFailed
                    data={data}
                />
            )}

            {data.installation_training === "yes" && (
                <InstallationStageCheckComplete
                    data={data}
                />
            )}

            {/* if previous stage is complete */}
            {data.installation_training === "yes" && (data.check_complete === "yes") && (
                <InstallationStageDocumentsReturned
                    data={data}
                />
            )}

        </>

    );

}

export default InstallationStages;
