import React, { useMemo } from 'react';
import InvoicesStagesVoidInvoice from './InvoicesStagesVoidInvoice';
import InvoicesStagesPayment from './InvoicesStagesPayment';
import InvoicesStagesCreditInvoice from './InvoicesStagesCreditInvoice';
import InvoicesStagesGenerateOfficialReceipt from './InvoicesStagesGenerateOfficialReceipt';
import { userHasRole } from '@dex/bubl-dash';
import InvoicesStagesSendEmail from './InvoicesStagesSendEmail';


const InvoicesStages: React.FC<any> = (props: any) => {

    const { data } = props;

    const isSuper = userHasRole("super");

    return useMemo(() => (

        <>
            {data.deposit > 0 && isSuper &&
                <InvoicesStagesGenerateOfficialReceipt
                    data={data}
                />
            }
            <InvoicesStagesPayment
                data={data}
            />

            <InvoicesStagesVoidInvoice
                data={data}
            />

            {/* @ts-ignore */}
            {[data.status === "void" || data.status === "credit"] &&

                <InvoicesStagesCreditInvoice
                    data={data}
                />

            }

        </>

    ), [data]);

}

export default InvoicesStages;
