import { dateTime } from "@dex/bubl-helpers";

export function dayDateTime(date) {

    if (!date) return "";

    if (typeof date === "object" && date.str) date = date.str;

    return dateTime(date, "dddd, D MMM YYYY, h:mm A");

}