import React, { useMemo } from 'react';
import ProformaInvoicesStagesVoidInvoice from './ProformaInvoicesStagesVoidInvoice';
import ProformaInvoicesStagesPayment from './ProformaInvoicesStagesPayment';
import ProformaInvoicesStagesVerifyPayments from './ProformaInvoicesStagesVerifyPayments';
import ProformaInvoicesStagesGenerateInvoice from './ProformaInvoicesStagesGenerateInvoice';
import ProformaInvoicesStagesLinkToInvoice from './ProformaInvoicesStagesLinkToInvoice';
import ProformaInvoicesStagesSendEmail from './ProformaInvoicesStagesSendEmail';


const ProformaInvoicesStages: React.FC<any> = (props: any) => {

    const { data, form } = props;

    return useMemo(() => (

        <>
            {data.status !== "paid" &&
                <ProformaInvoicesStagesGenerateInvoice
                    data={data}
                />
            }

            {(!data.invoice_id || data.link_to_invoice) &&
                <ProformaInvoicesStagesLinkToInvoice
                    data={data}
                />
            }

            {data.generate_invoice !== "yes" &&
                <>
                    <ProformaInvoicesStagesPayment
                        data={data}
                    />

                </>
            }



            {data.status === "paid" && data.generate_invoice !== "yes" &&
                <ProformaInvoicesStagesVerifyPayments
                    data={data}
                />
            }

            {data.payment_verified !== "yes" && data.generate_invoice !== "yes" &&
                <ProformaInvoicesStagesVoidInvoice
                    data={data}
                />
            }

        </>

    ), [data, form.hash]);

}

export default ProformaInvoicesStages;
