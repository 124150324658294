import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldAutocomplete, FieldRadio, FieldRelationship } from '@dex/bubl-dash';

const ServiceStageSelectEngagementPlan: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_select_engagement_plan");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"select_engagement_plan"}
            heading={"Select Engagement Plan"}
            editCaps={["stage_select_engagement_planServices"]}
            editable={data.select_engagement_plan && data.prepare_invoice !== "yes"}
            showForm={data.select_engagement_plan !== "yes"}
            showMeta={data.select_engagement_plan === "yes"}
            passed={data.select_engagement_plan === "yes"}
            showSave={form.changed}
            columnA={[
                {
                    label: "Previous Acquirer",
                    key: "previous_acquirer",
                    format: "modelLink"
                },
                {
                    label: "Previous Engagement Plan",
                    key: "previous_engagement_plan",
                    format: "modelLink"
                },
                {
                    label: "Previous Device Type",
                    key: "previous_engagement_plan.device_type",
                    format: "titleCase"
                },
            ]}
            columnB={[
                {
                    label: "New Acquirer",
                    key: "acquirer",
                    format: "modelLink"
                },
                {
                    label: "New Engagement Plan",
                    key: "engagement_plan",
                    format: "modelLink"
                },
                {
                    label: "New Device Type",
                    key: "engagement_plan.device_type",
                    format: "titleCase"
                },
            ]}
            columnC={[
                {
                    label: "Reset Active Date",
                    key: "reset_active_date",
                    format: "titleCase"
                },

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>
                                    <FieldRelationship
                                        form={form}
                                        label={"Acquirer"}
                                        name='acquirer_id'
                                        required={true}
                                        endpoint={'Acquirers'}
                                        preload={true}
                                        defaultValue={form.defaults.acquirer_id}
                                    />
                                </Col>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldRelationship
                                        key={form.values.acquirer_id}
                                        form={form}
                                        label={"Engagement Plan"}
                                        name='engagement_plan_id'
                                        required={true}
                                        model={'EngagementPlans'}
                                        preload={true}
                                        where={{ acquirer_id: form.values.acquirer_id }}
                                        defaultValue={form.defaults.engagement_plan_id}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldRadio
                                        form={form}
                                        name={"reset_active_date"}
                                        label={"Reset Active Date"}
                                        required={data.type === "swap_plan"}
                                        defaultValue={form.defaults.reset_active_date}
                                        options={[
                                            { value: "yes", label: "Yes" },
                                            { value: "no", label: "No" }
                                        ]}
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServiceStageSelectEngagementPlan;
