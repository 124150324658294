import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { ButtonRow, Events, FieldDate, FieldText, FieldWrap, Panel, } from '@dex/bubl-dash';
import { dayDateTime } from '../../../functions/formats';
import { validateDatePassed } from '../../../functions/sharedValidation';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';

const ServicesStageInTransit: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_in_transit");

    const reverse = ServicesApi.update(data.id, "stage_reverse");

    const status = ServicesApi.update(data.id, "check_status");


    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, false);

    }, []);

    const handleCheckStatus = useCallback(() => {

        status.run({
            onComplete: () => {

                Events.emit('refresh', true);

            }
        });

    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"in_transit"}
            heading={"In Transit"}
            editable={false}
            editCaps={["stage_in_transitServices"]}
            showForm={data.package_status !== "delivered"}
            showMeta={data.in_transit_on?.date}
            passed={data.package_status == "delivered"}
            showSave={data.package_status !== "delivered"}
            reverse={data.package_status === "delivered" ? null : reverse}
            last_stage={"outbound"}
            columnA={[
                {
                    label: "Delivered Date Time",
                    key: "delivered_date_time",
                    render: dayDateTime,
                }
            ]}
            columnB={[
                {
                    label: "Status",
                    key: "package_status",
                    format: "status"
                },
            ]}
            columnC={[
                {
                    label: "Details",
                    key: "package_details",
                    format: "titleCase"
                }
            ]}
            fields={
                <>
                    {data.package_status !== "delivered" &&
                        <>
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>
                                    <FieldDate
                                        form={form}
                                        name={"delivered_date_time"}
                                        time={true}
                                        required={false}
                                        label={"Delivered Date Time"}
                                        defaultValue={form.defaults.delivered_date_time}
                                        disabledDate={isDateDisabled}
                                    />
                                </Col>

                                <Col col={{ xs: 0, md: 2 }}>

                                </Col>

                                <Col col={{ xs: 24, md: 8 }} justify={"end"}>

                                    <FieldWrap>

                                        <Button
                                            label="Auto Check Tracking Status"
                                            loading={status.loading}
                                            onClick={handleCheckStatus}
                                        />
                                    </FieldWrap>

                                </Col>

                            </Row>
                        </>
                    }

                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading, status.hash]);

}

export default ServicesStageInTransit;
