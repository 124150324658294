import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import ServicesApi from '../ServicesApi';
import ServiceStagePrepareRentalInvoice from './ServiceStagePrepareRentalInvoice';
import ServiceStagePrintInvoice from './ServiceStagePrintInvoice';
import ServiceStageRecordVerified from './ServiceStageRecordVerified';
import ServicesStageDepositPayment from './ServiceStageDepositPayment';
import ServicesStageVerifyPayments from './ServiceStageVerifyPayments';
import ServicesStageSelectWarrantyStart from './ServicesStageSelectWarrantyStart';
import ServicesStagesServiceNotes from './ServicesStageServiceNotes';

const ServiceStagesRental: React.FC<any> = (props: any) => {

    const { data } = props;

    const invoice = data.invoice;
    const proforma = data.proforma_invoice;

    return (

        <>
            <ServicesStageSelectWarrantyStart
                data={data}
            />

            {data.warranty === "yes" &&
                <ServiceStagePrepareRentalInvoice
                    data={data}
                />
            }

            {data.prepare_proforma_invoice === "yes" && (

                <>

                    <ServiceStagePrintInvoice
                        data={data}
                    />

                    <ServicesStageDepositPayment
                        data={data}
                        apiService={ServicesApi}
                    />

                    {proforma?.status === "paid" && (
                        <ServicesStageVerifyPayments
                            data={data}
                            apiService={ServicesApi}
                        />
                    )}

                </>
            )}

            {invoice?.status === "paid" && (

                <ServiceStageRecordVerified
                    data={data}
                />

            )}

            <ServicesStagesServiceNotes
                data={data}
            />


        </>

    );

}

export default ServiceStagesRental;
