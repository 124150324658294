import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldRadio, FieldRelationship, FieldText, Line, Space, FieldUpload, FieldDate, useApp } from '@dex/bubl-dash';
import { userHasCap } from '../../../functions/useUser';
import ServiceStageAttemptFailed from './ServiceStageAttemptFailed';
import { validateDatePassed } from '../../../functions/sharedValidation';
import ServiceStageIncompleteMessages from './ServiceStageIncompleteMessages';

const ServiceStageDetailProgrammingOnsiteSwap: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const app = useApp();

    const process = ServicesApi.update(data.id, "stage_service_detail_programming",);

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "complete";

    const isStaff = userHasCap(["staff"]);
    const isTechnician = userHasCap(["technician"]);

    let last_stage = "";
    if (data.collection_method === "postage") {
        last_stage = "in_transit"
    } else {
        last_stage = "outbound"
    };

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, isTechnician);

    }, []);

    return useMemo(() => (
        <Stage
            data={data}
            form={form}
            process={process}
            name={"service_complete"}
            heading={"Service Detail (Onsite-Swap)"}
            editable={false}
            showForm={data.service_complete !== "yes"}
            showMeta={data.service_complete}
            passed={data.service_complete === "yes"}
            editCaps={["stage_service_detail_programmingServices"]}
            showSave={form.changed}
            reverse={data.service_complete === "yes" && isStaff ? null : reverse}
            last_stage={last_stage}
            renderMeta={() => (
                <>
                    <ServiceStageIncompleteMessages
                        data={data}
                    />
                </>
            )}
            columnA={[
                {
                    label: "Service Complete",
                    key: "service_complete",
                    format: "titleCase",
                },
                data.service_complete === "yes" && {
                    label: "Technician",
                    key: "technician",
                    format: "modelLink",
                },
                data.service_complete === "yes" && {
                    label: "Service Date",
                    key: "service_date",
                    format: "date"
                },
                {
                    label: "Terminal Swapped",
                    key: "terminal_swapped",
                    format: "titleCase",
                    hideIfEmpty: true,
                },
                data.terminal_swapped === "yes" && {
                    label: "New Serial Number",
                    key: "serial",
                },

                {
                    label: "Power Adapter Swapped",
                    key: "adapter_swapped",
                    format: "titleCase",
                    hideIfEmpty: true,
                },
                data.adapter_swapped === "yes" && {
                    label: "New Power Adapter Type",
                    key: "adapter_type",
                    format: "intl",
                    prefix: "service.adapter_type"
                },
            ].filter(Boolean)}
            columnB={[
                {
                    label: "Sim Card Swapped",
                    key: "sim_card_swapped",
                    format: "titleCase",
                    hideIfEmpty: true,
                },
                data.sim_card_swapped === "yes" && {
                    label: "New SIM Card No",
                    key: "sim_card_id",
                },
                {
                    label: "Swivel Swapped",
                    key: "swivel_swapped",
                    format: "titleCase",
                    hideIfEmpty: true,
                },
                data.swivel_swapped === "yes" && {
                    label: "New Swivel Serial",
                    key: "swivel_serial",
                },

                {
                    label: "Router Swapped",
                    key: "router_swapped",
                    format: "titleCase",
                    hideIfEmpty: true,
                },
                data.router_swapped === "yes" && {
                    label: "New Router Serial",
                    key: "router_serial",
                },
                {
                    label: "Docking Swapped",
                    key: "docking_swapped",
                    format: "titleCase",
                    hideIfEmpty: true,
                },
                data.docking_swapped === "yes" && {
                    label: "New Docking Serial",
                    key: "docking_serial",
                },
            ].filter(Boolean)}
            columnC={[
                {
                    label: "Service Report Photo",
                    key: "service_photo",
                    format: "file",
                },
                {
                    label: "Terminal Photo Back",
                    key: "terminal_photo_back",
                    format: "file",
                },
                {
                    label: "Terminal Photo Front",
                    key: "terminal_photo_front",
                    format: "file",
                },
            ].filter(Boolean)}
            fields={
                <>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                label={"Service Complete"}
                                name="service_complete"
                                required={true}
                                defaultValue={
                                    form.defaults.service_complete
                                }
                                options={[
                                    { label: "Yes", value: "yes" },
                                    // { label: "No", value: "no" },
                                    { label: "Failed", value: "failed" }
                                ]}
                            />

                        </Col>

                        {form.values.service_complete === "yes" &&
                            <>
                                <Col col={{ xs: 24, md: 8 }}>
                                </Col>
                                {!isTechnician &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRelationship
                                            form={form}
                                            label="Technician"
                                            name="technician_id"
                                            required={true}
                                            model={"Users"}
                                            preload={true}
                                            where={{ roles: "technician", provider_id: data.provider_id }}
                                            defaultValue={form.defaults.technician_id}
                                        />

                                    </Col>
                                }
                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldDate
                                        form={form}
                                        name={"service_date"}
                                        required={true}
                                        label={"Service Date"}
                                        defaultValue={form.defaults.service_date || ""}
                                        disabledDate={isDateDisabled}
                                    />

                                </Col>
                            </>
                        }

                    </Row>

                    {form.values.service_complete === "failed" &&
                        <>
                            <ServiceStageAttemptFailed
                                form={form}
                                data={data}
                            />
                        </>
                    }

                    {form.values.service_complete === "yes" && (
                        <>

                            {data.swap_terminal !== "no" &&
                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Terminal Swapped"}
                                            name="terminal_swapped"
                                            required={true}
                                            defaultValue={form.defaults.terminal_swapped}
                                            options={[
                                                {
                                                    label: "Yes",
                                                    value: "yes",
                                                },
                                                {
                                                    label: "No",
                                                    value: "no",
                                                },
                                            ]}
                                        />

                                    </Col>

                                    {form.values.terminal_swapped === "yes" && (

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                name={'temp.serial'}
                                                disabled
                                                label='New Terminal Serial No.'
                                                defaultValue={form.defaults.serial}
                                            />

                                        </Col>

                                    )}

                                </Row>
                            }

                            {data.swap_adapter !== "no" &&
                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Adapter Swapped"}
                                            name="adapter_swapped"
                                            required={true}
                                            defaultValue={form.defaults.adapter_swapped}
                                            options={[
                                                {
                                                    label: "Yes",
                                                    value: "yes",
                                                },
                                                {
                                                    label: "No",
                                                    value: "no",
                                                },
                                            ]}
                                        />

                                    </Col>

                                    {form.values.adapter_swapped === "yes" && (
                                        <>
                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldText
                                                    form={form}
                                                    name={'temp.adapter'}
                                                    disabled
                                                    label='New Power Adapter Type'
                                                    defaultValue={app.intl("service.adapter_type." + data.adapter_type)}
                                                />

                                            </Col>

                                        </>
                                    )}
                                </Row>
                            }

                            {data.swap_sim_card !== "no" &&
                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Sim Card Swapped"}
                                            name="sim_card_swapped"
                                            required={true}
                                            defaultValue={form.defaults.sim_card_swapped}
                                            options={[
                                                {
                                                    label: "Yes",
                                                    value: "yes",
                                                },
                                                {
                                                    label: "No",
                                                    value: "no",
                                                },
                                            ]}
                                        />

                                    </Col>

                                    {form.values.sim_card_swapped === "yes" && (
                                        <>
                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldText
                                                    form={form}
                                                    name={'temp.sim_card'}
                                                    disabled
                                                    label='New Sim Card Serial No.'
                                                    defaultValue={data.sim_card_serial + " - " + data.telco_network}
                                                />

                                            </Col>

                                        </>
                                    )}
                                </Row>
                            }

                            {data.swap_swivel !== "no" &&
                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Swivel Swapped"}
                                            name="swivel_swapped"
                                            required={true}
                                            defaultValue={form.defaults.swivel_swapped}
                                            options={[
                                                {
                                                    label: "Yes",
                                                    value: "yes",
                                                },
                                                {
                                                    label: "No",
                                                    value: "no",
                                                },
                                            ]}
                                        />

                                    </Col>

                                    {form.values.swivel_swapped === "yes" && (
                                        <>
                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldText
                                                    form={form}
                                                    name={'temp.swivel'}
                                                    disabled
                                                    label='New Swivel Serial No.'
                                                    defaultValue={data.swivel_serial}
                                                />

                                            </Col>

                                        </>
                                    )}
                                </Row>
                            }

                            {data.swap_router !== "no" &&
                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Router Swapped"}
                                            name="router_swapped"
                                            required={true}
                                            defaultValue={form.defaults.router_swapped}
                                            options={[
                                                {
                                                    label: "Yes",
                                                    value: "yes",
                                                },
                                                {
                                                    label: "No",
                                                    value: "no",
                                                },
                                            ]}
                                        />

                                    </Col>

                                    {form.values.router_swapped === "yes" && (
                                        <>
                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldText
                                                    form={form}
                                                    name={'temp.router'}
                                                    disabled
                                                    label='New Router Serial No.'
                                                    defaultValue={data.router_serial}
                                                />

                                            </Col>

                                        </>
                                    )}
                                </Row>
                            }

                            {data.swap_docking !== "no" &&
                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Docking Swapped"}
                                            name="docking_swapped"
                                            required={true}
                                            defaultValue={form.defaults.docking_swapped}
                                            options={[
                                                {
                                                    label: "Yes",
                                                    value: "yes",
                                                },
                                                {
                                                    label: "No",
                                                    value: "no",
                                                },
                                            ]}
                                        />

                                    </Col>

                                    {form.values.docking_swapped === "yes" && (
                                        <>
                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldText
                                                    form={form}
                                                    name={'temp.docking'}
                                                    disabled
                                                    label='New Docking Serial No.'
                                                    defaultValue={data.docking_serial}
                                                />

                                            </Col>

                                        </>
                                    )}
                                </Row>
                            }

                            <Line top={"small"} bottom={"small"} />

                            <Row Row gutter={8} edge={true}>


                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Service Report Photo"}
                                        name={'service_photo'}
                                        required
                                        defaultValue={form.defaults.service_photo}
                                        limit={2}
                                    />

                                    <Space height={"small"} />

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Terminal Photo Back"}
                                        name={'terminal_photo_back'}
                                        required
                                        defaultValue={form.defaults.terminal_photo_back}
                                        limit={2}
                                    />

                                    <Space height={"small"} />

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Terminal Photo Front"}
                                        name={'terminal_photo_front'}
                                        required
                                        defaultValue={form.defaults.terminal_photo_front}
                                        limit={2}
                                    />

                                </Col>

                            </Row>

                        </>

                    )}

                </>

            }
        />
    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageDetailProgrammingOnsiteSwap;