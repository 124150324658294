import React, { useMemo } from 'react';
import ClaimStageMakePayment from './ClaimStageMakePayment';
import ClaimStageReview from './ClaimStageReview';
import ClaimStageMerge from './ClaimStageMerge';

const ClaimStages: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>
            {data.status !== "merged" &&
                <ClaimStageReview
                    data={data}
                />
            }

            {(data.review === "yes" && data.status !== "denied") && (

                <ClaimStageMakePayment
                    data={data}
                />

            )}

            {(data.agent?.manager && data.agent?.agency_id && (data.status === "approved" || data.status === "pending")) && (

                <ClaimStageMerge
                    data={data}
                />

            )}

        </>

    ), [data]);

}

export default ClaimStages;
