import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { FieldRelationship, FieldSelect, FieldTextArea, userHasCap } from '@dex/bubl-dash';

const ServiceStageOutbound: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_outbound");

    const reverse = ServicesApi.update(data.id, "stage_reverse");

    let last_stage = "";
    if (data.mode_of_update === "onsite_swap") {
        last_stage = "assign_provider"
    } else {
        last_stage = "prepare_package"
    };

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"outbound"}
            heading={"Outbound"}
            editCaps={["stage_outboundServices"]}
            editable={false}
            showForm={data.outbound !== "yes"}
            showMeta={data.outbound === "yes"}
            passed={data.outbound === "yes"}
            showSave={form.changed}
            reverse={data.outbound === "yes" ? null : reverse}
            last_stage={last_stage}
            columnA={[
                data.collection_method === "postage" && {
                    label: "Package Shipped",
                    key: "outbound",
                    format: "titleCase"
                },
                data.collection_method !== "postage" && {
                    label: "Package Collected",
                    key: "outbound",
                    format: "titleCase"
                },
            ].filter(Boolean)}
            columnB={[
                data.collection_method === "postage" && data.outbound === "yes" && {
                    label: "Courier Company",
                    key: "courier",
                    format: "titleCase"
                },
            ].filter(Boolean)}
            columnC={[

                data.collection_method === "postage" && data.outbound === "yes" && {
                    label: "Tracking Number",
                    key: "tracking_no",
                    render: (value, row) => {

                        let courier = "";

                        if (row.courier === "GDEX") {
                            courier = "gdex";
                        } else if (row.courier === "Citylink") {
                            courier = "citylink";
                        }

                        return <><a href={`https://tracking.my/${courier}/${value}`} target="_blank" rel="noopener noreferrer">
                            {value}
                        </a></>
                    }
                },
                data.collection_method === "postage" && data.outbound === "yes" && {
                    label: "Tracking Notes",
                    key: "tracking_notes",
                    format: "nl2br"
                },
                data.collection_method !== "postage" && data.outbound === "yes" && {
                    label: "Collected By",
                    key: "outbound_collector",
                    format: "ModelLink"
                },
            ].filter(Boolean)}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        {data.collection_method === "postage" && (

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Package Shipped"}
                                    name='outbound'
                                    required={true}
                                    defaultValue={form.defaults.outbound}
                                    options={[
                                        { label: "Yes", value: "yes" },
                                        { label: "No", value: "no" }
                                    ]}
                                />

                            </Col>

                        )}

                        {data.collection_method !== "postage" && (

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Package Collected"}
                                    name='outbound'
                                    required={true}
                                    defaultValue={form.defaults.outbound}
                                    options={[
                                        { label: "Yes", value: "yes" },
                                        { label: "No", value: "no" }
                                    ]}
                                />

                            </Col>

                        )}

                        {form.values.outbound === "yes" && data.collection_method === "postage" && (

                            <>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldSelect
                                        form={form}
                                        label={"Courier Company"}
                                        name='courier'
                                        required={true}
                                        defaultValue={form.defaults.courier}
                                        options={
                                            [
                                                { label: "GDEX", value: "GDEX" },
                                                { label: "City-Link", value: "Citylink" },
                                            ]}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldText
                                        form={form}
                                        label={"Tracking Number"}
                                        name='tracking_no'
                                        required={true}
                                        defaultValue={form.defaults.tracking_no}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldTextArea
                                        form={form}
                                        label={"Tracking Notes"}
                                        name='tracking_notes'
                                        required={false}
                                        defaultValue={form.defaults.tracking_notes}
                                    />

                                </Col>

                            </>
                        )}

                        {form.values.outbound === "yes" && form.values.collection_method !== "postage" && (

                            <>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Collected By"}
                                        name='outbound_collected_by'
                                        required={true}
                                        defaultValue={form.defaults.outbound_collected_by}
                                        model={'Users'}
                                        preload={true}
                                        where={{ roles: "technician", provider_id: data.provider_id }}
                                    />

                                </Col>

                            </>
                        )}

                    </Row>

                </>

            }

        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageOutbound;
