import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import Stage from '../../../elements/Stage/Stage';
import ProformaInvoicesApi from '../ProformaInvoicesApi';
import { Alert, FieldCheckbox, ModelLink, Space } from '@dex/bubl-dash';
import { formatNum } from '@dex/bubl-helpers';
import EmailInvoice from '../../../elements/EmailInvoice/EmailInvoice';


const ProformaInvoicesStagesVerifyPayments: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ProformaInvoicesApi.update(data.id, "stage_verify_payment");

    const isLinked = data.link_to_invoice === "yes";

    const hasTotal = !isLinked && data.total - data.deposit > 0;
    const hasDeposit = !isLinked && data.deposit > 0;


    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"payment_verified"}
            heading={"Verify Invoice & Payment"}
            editCaps={["stage_verify_paymentProformaInvoices"]}
            editable={false}
            showForm={data.payment_verified !== "yes"}
            showMeta={data.payment_verified}
            showSave={form.changed}
            passed={data.payment_verified === "yes"}
            columnA={[
                {
                    key: "payment_verified",
                    label: "Payment Verified",
                    format: "titleCase"
                },
            ]}
            columnB={[
                {
                    key: "invoice_id",
                    label: "Invoice No.",
                    render: (value, row) => (
                        <ModelLink data={{ id: value }} modelName="Invoices" />
                    )
                }
            ]}
            columnC={[
                {
                    key: "receipt_id",
                    label: "Receipt No.",
                    render: (value, row) => (
                        <ModelLink data={{ id: value }} modelName="OfficialReceipts" />
                    )
                }
            ]}
            // renderMetaAfter={() => (
            //     <>
            //         <EmailInvoice
            //             heading={"Send Invoice Email"}
            //             data={{ ...data, proforma_invoice_id: null }}
            //         />
            //     </>
            // )}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                name={"payment_verified"}
                                label={"Payment Verified"}
                                required={true}
                                defaultValue={form.defaults.payment_verified}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.payment_verified === "yes" &&
                            <Col col={{ xs: 24, md: 16 }}>


                                {isLinked &&
                                    <Alert
                                        type='info'
                                        message='Linked To Tax Invoice'
                                        description={"No tax invoice will be generated on save."}
                                    />
                                }

                                {!hasTotal &&
                                    <Alert
                                        type='info'
                                        message='Zero Value Invoice'
                                        description={"No tax invoice will be generated on save."}
                                    />
                                }

                                {(hasTotal && !isLinked) &&
                                    <Alert
                                        type='info'
                                        message='Generate Tax Invoice'
                                        description={"A tax invoice for RM " + formatNum(data.total - data.deposit) + " will be generated on save."}
                                    />
                                }

                                {(hasDeposit && !isLinked) &&
                                    <>
                                        <Space />
                                        <Alert
                                            type='info'
                                            message='Generate Official Receipt'
                                            description={"An official receipt of RM " + formatNum(data.deposit) + " will be generated on save."}
                                        />
                                    </>
                                }

                            </Col>
                        }

                    </Row>
                </>
            }
        />

    ), [props, form, process.loading]);

}

export default ProformaInvoicesStagesVerifyPayments;
