import InstallationsSettings from "../Installations/InstallationsSettings";
import ServicesSettings from "../Services/ServicesSettings";
import OpsMenuFilter from "./OpsMenuFilter";

export default function (app) {

    app.addMenu({
        key: "ops",
        zone: "ops",
        label: "Operation",
        priority: 1,
        addPath: ServicesSettings.path + "/create",
        addCaps: ServicesSettings.createCaps,
        items: [
            {
                label: "Installations",
                path: InstallationsSettings.path,
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Services",
                path: ServicesSettings.path,
                caps: ServicesSettings.indexCaps,
            },
            {
                label: "Fault Call",
                path: ServicesSettings.path + "/type/fault_call",
                caps: ServicesSettings.indexCaps,
            },
            {
                label: "Retrieval",
                path: ServicesSettings.path + "/type/retrieval",
                caps: ServicesSettings.indexCaps,
            },
            {
                label: "Paper Roll",
                path: ServicesSettings.path + "/type/paper_roll",
                caps: ServicesSettings.createCaps,
            },
            {
                label: "Programming",
                path: ServicesSettings.path + "/type/programming",
                caps: ServicesSettings.indexCaps,
            },
            {
                label: "Training",
                path: ServicesSettings.path + "/type/training",
                caps: ServicesSettings.indexCaps,
            },
            {
                label: "Yearly Rental",
                path: ServicesSettings.path + "/type/yearly_rental",
                caps: ServicesSettings.createCaps,
            },
            {
                label: "Amendment",
                path: ServicesSettings.path + "/type/amendment",
                caps: ServicesSettings.createCaps,
            },
            {
                label: "Annual Visitation",
                path: ServicesSettings.path + "/type/annual_visitation",
                caps: ServicesSettings.createCaps,
            },
            {
                label: "Swap Plan",
                path: ServicesSettings.path + "/type/swap_plan",
                caps: ServicesSettings.indexCaps,
            },
        ],
    });

    app.addMenu({
        key: InstallationsSettings.key,
        zone: InstallationsSettings.zone,
        label: "Status",
        name: "status",
        renderItems: OpsMenuFilter,
        priority: 2,
        items: [
            {
                label: "All",
                value: "-",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "New",
                value: "new",
                caps: InstallationsSettings.createCaps,
            },
            {
                label: "Testing",
                value: "testing",
                caps: InstallationsSettings.createCaps,
            },
            {
                label: "Perso",
                value: "perso",
                caps: InstallationsSettings.createCaps,
            },
            {
                label: "Quality Control",
                value: "quality_control",
                caps: InstallationsSettings.createCaps,
            },
            {
                label: "Assign Technician",
                value: "assign_technician",
                caps: InstallationsSettings.createCaps,
            },
            {
                label: "Outbound",
                value: "outbound",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "In Transit",
                value: "in_transit",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Installation",
                value: "installation",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Complete",
                value: "complete",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Complete Checked",
                value: "complete_checked",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Incomplete",
                value: "incomplete",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Cancelled",
                value: "cancelled",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Failed",
                value: "failed",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Verified",
                value: "verified",
                caps: InstallationsSettings.indexCaps,
            },
        ],
    });

    // app.addMenu({
    //     key: ServicesSettings.key,
    //     zone: ServicesSettings.zone,
    //     label: "Type",
    //     name: "type",
    //     renderItems: OpsMenuFilter,
    //     priority: 2,
    //     items: [
    //         {
    //             label: "All",
    //             value: "-",
    //             caps: ServicesSettings.indexCaps,
    //         },
    //         {
    //             label: "Fault Call",
    //             value: "fault_call",
    //             caps: ServicesSettings.indexCaps,
    //         },
    //         {
    //             label: "Retrieval",
    //             value: "retrieval",
    //             caps: ServicesSettings.indexCaps,
    //         },
    //         {
    //             label: "Paper Roll",
    //             value: "paper_roll",
    //             caps: ServicesSettings.createCaps,
    //         },
    //         {
    //             label: "Programming",
    //             value: "programming",
    //             caps: ServicesSettings.indexCaps,
    //         },
    //         {
    //             label: "Training",
    //             value: "training",
    //             caps: ServicesSettings.indexCaps,
    //         },
    //         {
    //             label: "Yearly",
    //             value: "maintenance",
    //             caps: ServicesSettings.createCaps,
    //         },
    //         {
    //             label: "Amendment",
    //             value: "amendment",
    //             caps: ServicesSettings.createCaps,
    //         },
    //         {
    //             label: "Annual Visitation",
    //             value: "annual_visitation",
    //             caps: ServicesSettings.createCaps,
    //         },
    //         {
    //             label: "Swap Plan",
    //             value: "swap_plan",
    //             caps: ServicesSettings.indexCaps,
    //         },
    //     ],
    // });

    app.addMenu({
        key: ServicesSettings.key,
        zone: ServicesSettings.zone,
        label: "Status",
        name: "status",
        renderItems: OpsMenuFilter,
        priority: 2,
        items: [
            {
                label: "All",
                value: "-",
                caps: ServicesSettings.indexCaps,
            },
            {
                label: "New",
                value: "new",
                caps: ServicesSettings.createCaps,
            },
            {
                label: "Pending",
                value: "pending",
                caps: ServicesSettings.createCaps,
            },
            {
                label: "In Progress",
                value: "in_progress",
                caps: ServicesSettings.indexCaps,
            },
            {
                label: "Perso",
                value: "perso",
                caps: ServicesSettings.createCaps,
            },
            {
                label: "Quality Control",
                value: "quality_control",
                caps: ServicesSettings.createCaps,
            },
            {
                label: "Outbound",
                value: "outbound",
                caps: ServicesSettings.createCaps,
            },
            {
                label: "In Transit",
                value: "in_transit",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Inbound",
                value: "inbound",
                caps: ServicesSettings.indexCaps,
            },
            {
                label: "Complete",
                value: "complete",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Complete Checked",
                value: "complete_checked",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Incomplete",
                value: "incomplete",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Cancelled",
                value: "cancelled",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Failed",
                value: "failed",
                caps: InstallationsSettings.indexCaps,
            },
            {
                label: "Verified",
                value: "verified",
                caps: InstallationsSettings.indexCaps,
            },
        ],
    });


}