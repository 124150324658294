import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import ServiceStageDeliveryComplete from './ServiceStageDeliveryComplete';
import ServiceStageOutbound from './ServiceStageOutbound';
import ServiceStagePreparePackage from './ServiceStagePreparePackage';
import ServicesStageInTransit from './ServicesStageInTransit';

const ServiceStagesPaperRoll: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <ServiceStagePreparePackage
                data={data}
            />

            {data.prepare_package === "yes" && (

                <ServiceStageOutbound
                    data={data}
                />

            )}

            {data.outbound === "yes" && data.collection_method === "postage" && (

                <>
                    <ServicesStageInTransit
                        data={data}
                    />

                </>

            )}

            {(
                (data.in_transit === "yes" || data.package_status === "delivered") ||
                (data.collection_method !== "postage" && data.outbound === "yes")
            ) && (

                    <ServiceStageDeliveryComplete
                        data={data}
                    />

                )}

        </>

    ), [data]);

}

export default ServiceStagesPaperRoll;
