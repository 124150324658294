import React, { useCallback, useRef, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Button, ButtonRow, Events, FieldHidden, FieldText, Form, Line, MetaTable, Row, useApp } from '@dex/bubl-dash';
import { FaCheckCircle } from 'react-icons/fa';

import styles from "./Stage.module.scss";
import Alert from '@dex/bubl-dash/src/lib/components/Alert/Alert';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';

const Stage: React.FC<StageProps> = (props) => {

    const { data, meta, showMeta, showSave, editCaps, viewCaps, restrictedMessage, fields, validate, name, form, process, reverse, editable, heading, passed } = props;

    const [showForm, setShowForm] = useState(props.showForm);

    const app = useApp();
    const [nav] = app.store('nav');

    let canEdit = true;
    let canView = true;

    if (viewCaps && !app.userCan(viewCaps)) canView = false;
    if (editCaps && !app.userCan(editCaps)) canEdit = false;

    const handleSubmit = useCallback((values) => {

        if (validate) {

            try {

                validate(values);

            } catch (e: any) {

                app.alert(e.message, 'error');

                return;

            }

        }

        process.run({
            data: values,
            onComplete: (response) => {

                app.alert(props.successMessage || 'Stage Saved', 'success');

                // app.navigate(nav, 'update', {});
                Events.emit('refresh', { background: true });

            }
        });

    }, [])

    const handleReverse = useCallback((values) => {

        if (!reverse) return;

        reverse.run({
            data: { stage: props.last_stage },
            onComplete: (response) => {

                app.alert('Stage Reverse', 'success');

                app.navigate(nav, 'update', {});

            }
        });

    }, []);

    const classNames: any = [];

    if (passed) classNames.push(styles["stage-passed"]);
    else classNames.push(styles["stage-pending"]);

    if (!canView) return <></>;

    return (

        <>

            <Panel
                heading={<>{passed && <FaCheckCircle />}{heading}</>}
                className={classNames.join(" ")}
                actions={() => (
                    <>
                        {editable &&
                            <Button
                                label={showForm ? "Cancel" : "Edit Stage"}
                                type={"faded"}
                                size={"small"}
                                onClick={setShowForm.bind(null, !showForm)}
                            />
                        }
                        {reverse &&
                            <Button
                                label={"Reverse Stage"}
                                type={"faded"}
                                size={"small"}
                                onClick={handleReverse}
                                confirm={{
                                    title: "Are You Sure?",
                                    message: "Are you sure you want to reverse this stage? This process cannot be undone."
                                }}
                                loading={reverse.loading}
                            />
                        }
                    </>
                )}
            >

                {showMeta &&
                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Updated On & By",
                                key: name + "_on",
                                format: "onAndBy",
                                conditions: data[name + "_on"] ? true : false
                            },
                        ]}
                    />
                }

                {showMeta && (props.columnA || props.columnB || props.columnC) &&
                    <Line />
                }

                {showMeta && props.renderMeta &&
                    props.renderMeta()
                }

                {showMeta && (props.columnA || props.columnB || props.columnC) &&
                    <MetaTable
                        data={meta || data}
                        columnA={props.columnA}
                        columnB={props.columnB}
                        columnC={props.columnC}
                    />
                }

                {showMeta && props.renderMetaAfter &&
                    props.renderMetaAfter()
                }

                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={process.loading}
                    autoDiscardChanges={true}
                >

                    <FieldHidden
                        form={form}
                        name='__last_updated'
                        value={data.updated?.date || data.created?.date}
                    />

                    {showMeta && showForm &&
                        <Line />
                    }

                    {showForm && !canEdit &&
                        <>
                            <Alert
                                type="warning"
                                message={"Access Denied"}
                                description={restrictedMessage}
                            />
                        </>
                    }

                    {showForm && canEdit &&
                        <>
                            {fields}
                        </>
                    }

                    {showForm && canEdit && (showSave || process.loading || process.error) &&
                        <>
                            <Line />

                            <ButtonRow>
                                <Button
                                    label={"Save"}
                                    loading={process.loading}
                                    onClick={form.triggerSubmit}
                                />
                                <Button
                                    label={"Reset"}
                                    type={"faded"}
                                    onClick={form.reset}
                                />
                            </ButtonRow>
                        </>
                    }

                </Form>

            </Panel>

            <Space />

        </>

    )

}

interface StageProps {
    [key: string]: any,
}

export default Stage;