import React from 'react';
import { get } from "@dex/bubl-helpers";
import ServicesApi from './ServicesApi';
import { Status, WhiteSpace, useApp } from '@dex/bubl-dash';
import { wpOldLink } from '../../functions/wpOldLink';
import OriginalModelLink from '../../elements/OriginalModelLink/OriginalModelLink';

let settings;

export default settings = {
    key: 'services',
    zone: 'ops',
    path: '/services',
    title: "Services",
    endpoint: ServicesApi.endpoint,
    modelName: 'Services',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewServices"],
    viewCaps: ["viewServices"],
    createCaps: ["createServices"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        const app = useApp();

        if (params && params.type) title = title + " / " + app.intl("service.type." + params.type);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "Fault Call",
                path: settings.path + "/type/fault_call",
                caps: settings.indexCaps,
            },
            {
                label: "Retrieval",
                path: settings.path + "/type/retrieval",
                caps: settings.indexCaps,
            },
            {
                label: "Paper Roll",
                path: settings.path + "/type/paper_roll",
                caps: settings.indexCaps,
            },
            {
                label: "Programming",
                path: settings.path + "/type/programming",
                caps: settings.indexCaps,
            },
            {
                label: "Training",
                path: settings.path + "/type/training",
                caps: settings.indexCaps,
            },
            {
                label: "Yearly Rental",
                path: settings.path + "/type/yearly_rental",
                caps: settings.indexCaps,
            },
            {
                label: "Amendment",
                path: settings.path + "/type/amendment",
                caps: settings.indexCaps,
            },
            {
                label: "Annual Visitation",
                path: settings.path + "/type/annual_visitation",
                caps: settings.indexCaps,
            },
            {
                label: "Swap Plan",
                path: settings.path + "/type/swap_plan",
                caps: settings.indexCaps,
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        let { type } = params || {};

        return [
            type === "annual_visitation" && {
                label: "Batch Upload",
                path: settings.path + '/upload/annual_visitation',
                caps: settings.createCaps
            },
            type === "yearly_rental" && {
                label: "Batch Upload",
                path: settings.path + '/upload/yearly_rental',
                caps: settings.createCaps
            },
            // type === "amendment" && {
            //     label: "Batch Upload",
            //     path: settings.path + '/upload/amendment',
            //     caps: settings.createCaps
            // },
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            },
        ].filter(Boolean)

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: ServicesApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'merchant',
                    preset: "link"
                },
                {
                    relation: 'acquirer',
                    preset: "link"
                },
                {
                    relation: 'technician',
                    preset: "link"
                },
                {
                    relation: 'provider',
                    preset: "link"
                },
                {
                    relation: 'terminal',
                    preset: "link"
                },
                {
                    relation: 'engagement_plan',
                    preset: "link"
                },
            ],
            order: ['created.date DESC'],
            limit: 10,
            skip: 0
        };

        let { status, type, batch } = params || {};

        if (status) filters.where.status = { inq: [status] };
        if (type) filters.where.type = { inq: [type] };
        if (batch) filters.where.batch = batch;

        return filters;

    },
    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "services", label: "Services" },
            user.caps.includes("staff") && { value: "servicesStockOut", label: "Services - Stock Out" },
            user.caps.includes("staff") && { value: "servicesStockIn", label: "Services - Stock In" },
        ].filter(Boolean);

        if (!user || !user.caps.includes("exportServices")) return [];

        return formats;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'original_id', 'title'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Service ID",
                key: "id",
                type: "text",
            },
            !params.type && {
                label: "Service Type",
                key: "type",
                type: "choice",
                options: [
                    { value: "fault_call", label: "Fault Call" },
                    { value: "retrieval", label: "Retrieval" },
                    { value: "paper_roll", label: "Paper Roll" },
                    { value: "programming", label: "Programming" },
                    { value: "training", label: "Training" },
                    { value: "yearly_rental", label: "Yearly Rental" },
                    { value: "amendment", label: "Amendment" },
                    { value: "annual_visitation", label: "Annual Visitation" },
                    { value: "swap_plan", label: "Swap Plan" },
                ]
            },
            {
                label: "Merchant",
                key: "merchant_id",
                type: "relationship",
                model: "Merchants"
            },
            {
                label: "Company Name",
                key: "company_name",
                type: "text",
            },
            {
                label: "TID",
                key: "tid",
                type: "text",
            },
            {
                label: "Service Provider",
                key: "provider_id",
                type: "relationship",
                model: "Providers"
            },
            {
                label: "Acquirer",
                key: "acquirer_id",
                type: "relationship",
                model: "Acquirers"
            },
            {
                label: "Technician",
                key: "technician_id",
                type: "relationship",
                model: "Users"
            },
            {
                label: "Serial Number",
                key: "serial_id",
                type: "relationship",
                model: "Terminals"
            },
            {
                label: "State",
                key: "state",
                type: "text",
            },

            params.type === "amendment" && {
                label: "Amendment Type",
                key: "amendment_type",
                type: "choice",
                options: [
                    { value: "change_merchant_details", label: "Change Merchant Details" },
                    { value: "change_date_status", label: "Change Date/Status" },
                    { value: "change_engagement_plan", label: "Change Engagement Plan" },
                    { value: "reuse_floating_merchant", label: "Reuse Floating Merchant" },
                ]
            },
            {
                label: "Assign Asset Date",
                key: "terminal_selected_on.date",
                type: "date",
            },
            {
                label: "Service Date",
                key: "service_date",
                type: "date",
            },
            {
                label: "Retrieved Date",
                key: "retrieval_details_on.date",
                type: "date",
            },
            {
                label: "Recover Asset Date",
                key: "recover_assets_on.date",
                type: "date",
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
            {
                label: "Terminal Swapped",
                key: "terminal_swapped",
                type: "choice",
                options: [
                    { value: "yes", label: "Yes" },
                    { value: "no", label: "No" },
                ]
            },
        ].filter(Boolean);

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Service ID",
                key: "this",
                format: "ModelLink",
                render: (value, row) => <OriginalModelLink data={row} />
            },

            {
                label: "Service Type",
                key: "type",
                format: "intl",
                prefix: "service.type"
            },
            {
                label: "Status",
                key: "status",
                format: "status",
                prefix: "service.status"
            },
            {
                label: "Merchant",
                key: "merchant",
                format: "ModelLink"
            },
            {
                label: "Company Name",
                key: "merchant.company_name",
            },
            params.type === "retrieval" && {
                label: "Missing Items",
                key: "collection_missing",
                render: (value, row) => (
                    <>
                        {value && value.map((v, index) => (
                            <p key={index}>{v}</p>
                        ))}
                    </>
                )
            },
            params.type === "amendment" && {
                label: "Amendment Type",
                key: "amendment_type",
                format: "intl",
                prefix: "service.amendment"
            },
            {
                label: "TID",
                key: "merchant.tid",
            },
            {
                label: "Service Provider",
                key: "provider",
                format: "ModelLink"
            },
            {
                label: "Acquirer",
                key: "acquirer",
                format: "ModelLink"
            },
            {
                label: "Technician",
                key: "technician",
                format: "ModelLink"
            },
            // {
            //     label: "Total Time",
            //     key: "total_time",
            // },
            // {
            //     label: "Stage Time",
            //     key: "stage_time",
            // },
            {
                label: "Serial Number",
                key: "terminal.serial",
            },
            {
                label: "State",
                key: "state",
            },
            {
                label: "City",
                key: "service_address.city",
                type: "text",
            },
            {
                label: "Contact Person",
                key: "person",
            },
            {
                label: "Phone",
                key: "phone",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: ServicesApi,
                settings: settings,
            }
        ].filter(Boolean);

        return columns;

    },
    singleFetch: ServicesApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'merchant',
                    // preset: "link"
                },
                {
                    relation: 'acquirer',
                    preset: "link"
                },
                {
                    relation: 'agent',
                    preset: "link"
                },
                {
                    relation: 'agency',
                    preset: "link"
                },
                {
                    relation: 'ticket',
                    preset: "link"
                },
                {
                    relation: 'faulty_terminal',
                    preset: "link"
                },
                {
                    relation: 'terminal',
                    preset: "link"
                },
                {
                    relation: 'provider',
                    preset: "link"
                },
                {
                    relation: 'technician',
                    preset: "link"
                },
                {
                    relation: 'outbound_collector',
                    preset: "link"
                },
                {
                    relation: 'engagement_plan'
                },
                {
                    relation: 'previous_engagement_plan',
                    preset: "link"
                },
                {
                    relation: 'previous_acquirer',
                    preset: "link"
                },
                {
                    relation: 'installation',
                    preset: "link"
                },
                {
                    relation: 'spare_terminal',
                    preset: "link"
                },
                {
                    relation: 'invoice',
                    // preset: "link"
                },
                {
                    relation: 'proforma_invoice',
                    // preset: "link"
                },
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        if (data) {
            title = <>
                {title}
                <WhiteSpace /> <Status status={data.type} prefix='service.type' />
                <WhiteSpace /> <Status status={data.status} prefix='service.status' />
            </>
        }

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        const data = fetch?.data || {};

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.createCaps,
            },
            (data.status !== "complete" && data.status !== "verified") && {
                label: "Cancel",
                path: path + "/cancel",
                caps: settings.createCaps,
            },
            {
                label: "Activity",
                path: path + "/activity"
            },
        ];

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
            wpOldLink(fetch.data),
        ].filter(Boolean);

    },

};